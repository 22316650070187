import React from "react"
import { Helmet } from "react-helmet"

import { useStaticQuery, graphql } from "gatsby"
import { withPrefix } from "gatsby";

import {format} from 'date-fns';

import { getEntityUrl } from '../lib/helpers'

import PropTypes from "prop-types"

import {getPageUrl} from '../lib/helpers'



export const query = graphql`
  query {
    seoQuery: strapi{ siteSetting {
      id
      site_name
      default_seo_description
      color_accent
      featured_pages {
        id
        title
        slug
        page_type {
          name
          slug_prefix
        }
      }
    }}
}
`


const MetaSchemaSEO = ({ title, description, image, article }) => {

  const data = useStaticQuery(query);
  const siteSetting = data.seoQuery.siteSetting;

  if( !image ){
    // check for main image on article
    if( article.main_image ){
      image = process.env.SITE_URL+article.main_image.imageFile.childImageSharp.gatsbyImageData.images.fallback.src
    }
  }
  const seo = {
    title: title || siteSetting.site_name,
    description: description || siteSetting.default_seo_description,
    image: image,
    shortTitle: article.shortTitle || article.primary_topic || title
  }
  if( article ){
    seo['url'] = process.env.SITE_URL+getPageUrl(article)
  }
  if( article && article.excerpt ){
    seo['description'] = article.excerpt
  }
  // define schema configurations for this website
  var schema_website_name = ``;
  if (siteSetting.site_name) {
    schema_website_name = `,"name":"`+siteSetting.site_name.replace(/["]+/g, "&quot;")+`"`;
  }
  var schema_website_description = ``;
  if (siteSetting.default_seo_description){
    schema_website_description = `,"description":"`+siteSetting.default_seo_description.replace(/["]+/g, "&quot;")+`"`;
  }
  var schema_website_searchaction = ``;
  if ( process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS) {
    schema_website_searchaction = `,"potentialAction":[{"@type":"SearchAction","target":"`+process.env.SITE_URL+`/search/?q=%22{query}%22","query":"optional","query-input":"name=query"}]`
  }
  
  // define schema configuraitons for this webpage
  var schema_publishedat = ``;
  if (article && article.published_at){
    var schema_publishedat = `,"datePublished":"`+format( Date.parse(article.published_at), "yyyy-MM-dd")+`"`;
  }
  var schema_updatedat = ``;
  if (article && article.updated_at){
    var schema_updatedat = `,"dateModified":"`+format( Date.parse(article.updated_at), "yyyy-MM-dd")+`"`;
  }
  var schema_name = ``;
  if ( article.title ){
    schema_name = `,"name":"`+article.title.replace(/["]+/g, "&quot;")+`"`
  }
  var schema_shortname = ``;
  if ( seo.shortTitle ){
    schema_shortname = `,"name":"`+seo.shortTitle.replace(/["]+/g, "&quot;")+`"`
  }
  var schema_about = ``;
  if ( article && article.primary_topic ){
    var schema_about = `,"about":{"@type":"Thing","name":"`+article.primary_topic.replace(/["]+/g, "&quot;")+`"}`
  }
  var schema_mainContentOfPage = `,"mainContentOfPage":{"@type":"WebPageElement","xpath":"//*[@id='mainContentOfPage']"}`
  var schema_description = ``
  if (description || (article && article.description)){
    var schema_description = `,"description":"`+seo.description.replace(/["]+/g, "&quot;")+`"`
  }
  var schema_keywords = ``;
  if (article && article.keyphrases && article.keyphrases.length){
    // add keywords to a list
    const keywords = [];
    for (var kw of article.keyphrases){
      keywords.push(kw.keyphrase.replace(/["]+/g, "&quot;"))
    }
    var schema_keywords = `,"keywords":"`+keywords.join()+`"`
  }
  
  // schema for article
  var is_article = article.title;
  if (is_article){

    var article_mainImage = "";
    if( image ){
      article_mainImage = `"image":"`+image+`",`;
    }

    var article_updatedAt = "";
    if( article.updated_at ){
      article_updatedAt = `"dateModified": "`+format( Date.parse(article.updated_at), "yyyy-MM-dd")+`",`
    }
    var article_about = "";
    if( article.primary_topic ){
      article_about = `"about": "`+article.primary_topic.replace(/["]+/g, "&quot;")+`",`
    }

    // get authors
    var article_authors = "";
    if( article.authors ){
      // first is author, rest are contributors
      var is_first_author = true
      var author_label = "author"
      for( const author of article.authors){
        // create data for this author
        var author_type = 'Person' // todo check if is organization?!
        // sameAs is a comma or enter seperated list of items, keep it as a list
        var author_sameAs = ""
        if( author.sameAs ){
          var sameAs_list = []
          for( var sameAs of author.sameAs.split(/,|\n/)){
            sameAs_list.push('"'+sameAs.replaceAll('"', "'")+'"')
          }
          author_sameAs = author_sameAs + `"sameAs": [`+sameAs_list.toString()+`],`
        }
        var author_image = ''
        if( author.main_image ){
          author_image = `"image": "`+process.env.SITE_URL+author.main_image.imageFile.childImageSharp.gatsbyImageData.images.fallback.src+`",`
        }
        article_authors = article_authors + `
        "`+author_label+`" : {
          "@context": "https://schema.org",
          "@type": "`+author_type+`",
          `+author_image+`
          "name": "`+author.name+`",
          `+author_sameAs+`
          "url": "`+process.env.SITE_URL+getEntityUrl(author)+`"
        },`

        if( is_first_author ){
          is_first_author = 'false'
          author_label = 'contributor'
        }
      }
    } else {
      // no author so use the org as the author
      var author_label = "author"
      var author_type = "Organization"
      article_authors = article_authors + `
        "`+author_label+`" : {
          "@context": "https://schema.org",
          "@type": "`+author_type+`",
          "name" : "`+siteSetting.site_name+`",
          "url" : "`+process.env.SITE_URL+`"
        },`
    }
    var article_section = "";
    if( false ){
      article_section = `"articleSection": "`+article.section+`",`
    }
    var article_mainEntity = "";
    if( false ){
      article_mainEntity = `"mainEntity": {
        "@type" : "Organization",
        "name" : "`+siteSetting.site_name+`",
        "url" : "`+process.env.SITE_URL+`"
      },`
    }

    var article_contentLocation = "";
    // if primary entity is a location then include it as the content location
    if( false ){
      article_contentLocation = `"contentLocation": {
        "@type": "AdministrativeArea",
        "name": "Auvers-sur-Oise"
      },`
    }

    var article_bodyText = ""
    // get all the article text
    if( false ){
      article_bodyText = `"articleBody" : "displaying the error code &quot;OC,&quot; it means that the",`
    }
  }
  
  // schema for this breadcrumb (default is no grandparent and no parent)
  var schema_breadcrumb_grandparent = ``
  var schema_breadcrumb_parent = ``
  var schema_breadcrumb_thisid = `2`
  // does this article have parent and grandparent?
  if (article.parent && article.parent.include_parent_when_in_breadcrumb_as_parent && article.parent.parent){
    // show grandparent? 
    var grandparent_title = article.parent.parent.title_short?article.parent.parent.title_short:article.parent.title
    grandparent_title = grandparent_title.replace(/["]+/g, "&quot;")
    schema_breadcrumb_grandparent = `,{"@type":"ListItem","position":2,"item":{"@type":"WebPage","@id":"`+process.env.SITE_URL+getPageUrl(article.parent.parent)+`#webpage","url":"`+process.env.SITE_URL+getPageUrl(article.parent.parent)+`","name":"`+grandparent_title+`"}}`
    var parent_title = article.parent.title_short?article.parent.title_short:article.parent.title
    parent_title = parent_title.replace(/["]+/g, "&quot;")
    schema_breadcrumb_parent = `,{"@type":"ListItem","position":3,"item":{"@type":"WebPage","@id":"`+process.env.SITE_URL+getPageUrl(article.parent)+`#webpage","url":"`+process.env.SITE_URL+getPageUrl(article.parent)+`","name":"`+parent_title+`"}}`
    schema_breadcrumb_thisid = `4`
  } else {
    // just parent?
    if (article.parent){
      var parent_title = article.parent.title_short?article.parent.title_short:article.parent.title
      parent_title = parent_title.replace(/["]+/g, "&quot;")
      schema_breadcrumb_parent = `,{"@type":"ListItem","position":2,"item":{"@type":"WebPage","@id":"`+process.env.SITE_URL+getPageUrl(article.parent)+`#webpage","url":"`+process.env.SITE_URL+getPageUrl(article.parent)+`","name":"`+parent_title+`"}}`
      schema_breadcrumb_thisid = `3`
    }
  }

  var title_appended = ` | ` + siteSetting.site_name;
  if ( siteSetting.site_name == "Error Solutions"){
    var title_appended = ` - ` + siteSetting.site_name;
  }


  // Q&A Section
  if (article && article.body){
    var dataset = null;
    var questions = [];
    for (var component of article.body){
      if (component.__typename == "STRAPI_ComponentContentQuestionAndAnswer") {
        questions.push(`{
          "@type": "Question",
          "name": "`+component.Question.replace(/["]+/g, "&quot;")+`",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "`+component.answer.replace(/["]+/g, "&quot;")+`"
          }
        }`)
      }
      // get dataset jsonld
      if (component.__typename == "STRAPI_ComponentDataTabularData") {
        if ("jsonld_schema_data" in component && component.jsonld_schema_data) {
          dataset = component.jsonld_schema_data
        }
      }
    }
  }
  


  var siteSettings_color_accent = "156dff" // default blue color
  if(data.seoQuery.siteSetting.color_accent){
    siteSettings_color_accent = data.seoQuery.siteSetting.color_accent
  }


  return (
    <>
    <style jsx>{`
      a:hover {
        color: #`+siteSettings_color_accent+`;
      }
    `}</style>
    <Helmet 
      title={seo.title + title_appended}
      htmlAttributes={{
        lang: 'en',
      }}>
      <meta name="description" content={seo.description} />
      {seo.image && <meta name="image" content={seo.image} />}

      {seo.url && <meta property="og:url" content={seo.url} />}
      {process.env.FB_APP_ID && <meta property="fb:app_id" content={process.env.FB_APP_ID} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}

      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      {process.env.GOOGLEANALYTICS_TRACKINGID && 
        <script async src={"https://www.googletagmanager.com/gtag/js?id="+process.env.GOOGLEANALYTICS_TRACKINGID}></script>
      }
      {!process.env.GOOGLEANALYTICS_TRACKINGID && process.env.GOOGLEANALYTICS4_TRACKINGID && 
        <script async src={"https://www.googletagmanager.com/gtag/js?id="+process.env.GOOGLEANALYTICS4_TRACKINGID}></script>
      }
      
      {process.env.ADSENSE_CLIENT_ID && 
        <script async src={"https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client="+process.env.ADSENSE_CLIENT_ID} crossOrigin="anonymous"></script>
      }

      {process.env.EZOIC_ACCOUNT_ID && <script async src={"//www.ezojs.com/ezoic/sa.min.js"}></script>}
      
    </Helmet>

    
    {
    // breadcrumb and site info
    article &&
    <Helmet script={[{
      type:"application/ld+json",
      innerHTML:`
      {"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"`+process.env.SITE_URL+`#website","url":"`+process.env.SITE_URL+`"`+schema_website_name+schema_website_description+schema_website_searchaction+`,"inLanguage":"en-US"}`+`,{"@type":"WebPage","@id":"`+process.env.SITE_URL+getPageUrl(article)+`#webpage","url":"`+process.env.SITE_URL+getPageUrl(article)+`"`+schema_name+schema_about+schema_mainContentOfPage+schema_publishedat+schema_updatedat+schema_description+schema_keywords+`,"isPartOf":{"@id":"`+process.env.SITE_URL+`#website"}`+`,"breadcrumb":{"@id":"`+process.env.SITE_URL+getPageUrl(article)+`#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["`+process.env.SITE_URL+getPageUrl(article)+`"]}]},{"@type":"BreadcrumbList","@id":"`+process.env.SITE_URL+getPageUrl(article)+`#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"item":{"@type":"WebSite","@id":"`+process.env.SITE_URL+`#website","url":"`+process.env.SITE_URL+`"`+schema_website_name+`}}`+schema_breadcrumb_grandparent+schema_breadcrumb_parent+`,{"@type":"ListItem","position":`+schema_breadcrumb_thisid+`,"item":{"@type":"WebPage","@id":"`+process.env.SITE_URL+getPageUrl(article)+`#webpage","url":"`+process.env.SITE_URL+getPageUrl(article)+`"`+schema_shortname + `}}]}]}`}]} />
    }
    
    {   
    // schema for article
    is_article &&
    <Helmet script={[{
      type:"application/ld+json",
      innerHTML:`
      {
        "@context" : "http://schema.org",
        "@type" : "Article",
        "name" : "`+article.title.replace(/["]+/g, "&quot;")+`",
        `+article_authors+`
        "datePublished" : "`+format( Date.parse(article.published_at), "yyyy-MM-dd")+`"`+schema_keywords+`,
        `+article_updatedAt+`
        `+article_section+`
        `+article_mainEntity+`
        `+article_about+`
        "headline": "`+article.title.replace(/["]+/g, "&quot;")+`",
        `+article_mainImage+`
        `+article_bodyText+`
        "url" : "`+process.env.SITE_URL+getPageUrl(article)+`",
        "publisher" : {
          "@type" : "Organization",
          "name" : "`+siteSetting.site_name+`",
          "url" : "`+process.env.SITE_URL+`"
        }
      }`}]} />
    }
    
    {
    // Q&A section
    questions && questions.length>0 &&
    <Helmet script={[{
      type:"application/ld+json",
      innerHTML:`
      {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [`+questions.join()+`]
    }`}]} />
    }
    { dataset &&
    <Helmet script={[{
      type:"application/ld+json",
      innerHTML: JSON.stringify(dataset)}]} />
    }

    { (process.env.GOOGLEANALYTICS_TRACKINGID && process.env.GOOGLEANALYTICS4_TRACKINGID ) && 
    <Helmet 
    script={[{ 
      type: 'text/javascript', 
      innerHTML: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '`+process.env.GOOGLEANALYTICS_TRACKINGID+`');
      gtag('config', '`+process.env.GOOGLEANALYTICS4_TRACKINGID+`');`
    }]}
    />
    }
    { (!process.env.GOOGLEANALYTICS_TRACKINGID && process.env.GOOGLEANALYTICS4_TRACKINGID ) && 
    <Helmet 
    script={[{ 
      type: 'text/javascript', 
      innerHTML: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '`+process.env.GOOGLEANALYTICS4_TRACKINGID+`');`
    }]}
    />
    }

    </>
  )
}

export default MetaSchemaSEO

MetaSchemaSEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
}

MetaSchemaSEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
